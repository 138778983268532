import { Button, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { Icon } from '..';
import { Typography } from '../Typography/Typography';

export interface CopyLinkButtonProps {
  label?: string;
  handleClick: () => void;
}

const CopyLinkButton: FC<CopyLinkButtonProps> = ({
  label = 'Copy link',
  handleClick,
}) => {
  const theme = useTheme();

  return (
    <Button
      startIcon={
        <Icon
          name="copy-outline"
          size="medium"
          fill={theme.palette.text.secondary}
        />
      }
      onClick={handleClick}
    >
      <Typography
        variant="body1"
        textTransform="none"
        color={theme.palette.text.secondary}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default CopyLinkButton;
